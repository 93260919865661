import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import axios from "axios";
import { toast } from "react-toastify";
import BaseURL from "../../config/baseURL";
import fffAPI from "../../config/fffAPI";
import Header from "../../components/Header/Header";
import "react-toastify/dist/ReactToastify.css";
import "./adminFFF.css";

const socket = io.connect(BaseURL.SOCKET_URL);

const AdminFFFPage = () => {
	const [questions, setQuestions] = useState([]);
	const [selectedQuestionId, setSelectedQuestionId] = useState(null);
	const [selectedQuestion, setSelectedQuestion] = useState(null);
	const [shownQuestions, setShownQuestions] = useState([]);
	const [answersVisible, setAnswersVisible] = useState(false);
	const [loading, setLoading] = useState(true);
	const [correctAnswer, setCorrectAnswer] = useState([]);
	const [results, setResults] = useState([]);
	const [resultsVisible, setResultsVisible] = useState(false);

	useEffect(() => {
		const fetchQuestions = async () => {
			try {
				const questionResponse = await axios.get(fffAPI.QUESTIONS_URL, {
					withCredentials: true,
				});
				const shownResponse = await axios.get(
					fffAPI.SHOWN_QUESTIONS_URL
				);

				if (questionResponse.data) {
					setQuestions(questionResponse.data);
				}
				if (shownResponse.data) {
					setShownQuestions(
						shownResponse.data.map((q) => q.question_id)
					);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading(false);
			}
		};
		fetchQuestions();
	}, []);

	const fetchShownQuestionsAndResult = async () => {
		try {
			const response = await axios.get(fffAPI.SHOWN_QUESTIONS_URL);
			if (response.data) {
				setShownQuestions(response.data.map((q) => q.question_id));
			}

			const resultResponse = await axios.get(fffAPI.RESULTS_URL, {
				withCredentials: true,
			});
			if (resultResponse.data) {
				setResults(resultResponse.data.results);
			}
		} catch (error) {
			console.error("Error fetching shown questions or results:", error);
		}
	};

	const showQuestion = async (q_id) => {
		const question = questions.find((q) => q.q_id === q_id);
		if (question) {
			await socket.emit("fff-show_question", question);
			toast.success(`Question ${q_id} shown to players!`);
			setSelectedQuestionId(q_id);
			setSelectedQuestion(question);
		}
		setAnswersVisible(false);
		setResultsVisible(false);
	};

	const showOptions = () => {
		socket.emit("fff-show_options", selectedQuestionId);
		toast.success("Options displayed to players!");
	};

	const formatTime = (time) => {
		const parts = time.split(":");
		return parts[2];
	};

	const showAnswers = () => {
		const selected = questions.find((q) => q.q_id === selectedQuestionId);
		if (selected) {
			socket.emit("fff-show_answers"); // Emit correct order to players
			setCorrectAnswer(selected.correct_sequence);
			toast.success("Answers displayed to players!");
			setAnswersVisible(true);
			fetchShownQuestionsAndResult();
		}
	};

	const showResult = () => {
		socket.emit("fff-show_result");
		toast.success("Results displayed to players!");
		fetchShownQuestionsAndResult();
		setResultsVisible(true);
		setSelectedQuestionId(null);
	};

	return (
		<>
			<Header />
			<div className="admin-fff-container">
				<h1 className="admin-fff-heading">Admin FFF Control</h1>
				{loading ? (
					<p>Loading questions...</p>
				) : (
					<>
						<div className="question-button-container">
							{questions.map((q) => (
								<button
									key={q.q_id}
									className="question-button"
									onClick={() => showQuestion(q.q_id)}
									disabled={shownQuestions.includes(q.q_id)}
									title={q.question_text}>
									Q. {q.q_id}
								</button>
							))}
						</div>
						<div className="button-container">
							<button
								onClick={showOptions}
								disabled={!selectedQuestionId}
								className="control-button">
								Show Options
							</button>
							<button
								onClick={showAnswers}
								disabled={!selectedQuestionId}
								className="control-button">
								Show Answers
							</button>
							<button
								onClick={showResult}
								disabled={!selectedQuestionId}
								className="control-button">
								Show Result
							</button>
						</div>
					</>
				)}
			</div>
			<div className="cards-container">
				{answersVisible && (
					<div className="options-card">
						<h3 className="admin-fff-card-heading">
							Correct Answer
						</h3>
						{correctAnswer.split("").map((opt, index) => {
							return (
								<div key={index} className="option-item">
									{`${opt}: ${
										selectedQuestion[
											`option_${opt.toLowerCase()}`
										]
									}`}
								</div>
							);
						})}
					</div>
				)}
				{resultsVisible && (
					<div className="results-card">
						<h3 className="admin-fff-card-heading">Results</h3>
						{results.map((result, index) => (
							<div
								key={index}
								className={`result-item 
                                    ${result.isWinner ? "winner" : ""} 
                                    ${
										result.isCorrect ? "correct" : "wrong"
									} `}>
								{result.user}
								<span className="time">
									{(() => {
										const formattedTime = formatTime(
											result.time
										);
										const isNotValidTime = /^20\.\d+$/.test(
											formattedTime
										);
										return isNotValidTime
											? "—"
											: `${formattedTime} seconds`;
									})()}
								</span>
							</div>
						))}
					</div>
				)}
			</div>
		</>
	);
};

export default AdminFFFPage;
